import { CdkDragDrop, CdkDragEnter } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDay } from '../types';
import { MessagesService, MomentService, Vehicle } from '@fc-core';
import {
  FullSchedule,
  ScheduleInterface,
} from 'src/app/core/models/operator-schedule.model';
import moment from 'moment';
import { Moment } from 'moment';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  clearCurrentDay,
  setClickedDay,
} from '@fc-shared/ui/calendar/store/schedule-calendar.actions';
import { DialogsService } from '@fc-core/services/dialogs.service';
import { DateContentService } from '@fc-shared/ui/calendar/date-content/date-content.service';
import { ConfirmDialogData } from '@fc-shared/ui/confirm-dialog/confirm-dialog.data';

@Component({
  selector: 'fc-date-content',
  templateUrl: './date-content.component.html',
  styleUrls: ['./date-content.component.scss'],
  providers: [DateContentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() itemType: 'vehicle' | 'driver';
  @Input() itemTemplate: TemplateRef<any>;
  @Input() dateType: 'year' | 'multi-year';
  @Input() eventsList: FullSchedule[] = [];
  @Input() loading: boolean;

  @Input('currentDate') set setCurrentDate(date: Moment) {
    this.currentDate = date;
    this.generateCalendar();
  }

  @Input() dayClickFunction: Function;
  @Input() vehicleList: Vehicle[];
  @Output() eventMoved: EventEmitter<ScheduleInterface> = new EventEmitter();
  @Output() editEvent: EventEmitter<FullSchedule> = new EventEmitter();
  @Output() deleteEvent: EventEmitter<FullSchedule> = new EventEmitter();
  hoveredEvent: FullSchedule;
  currentDate: moment.Moment;
  namesOfDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  month: CalendarDay[] = [];
  monthRow: CalendarDay[][] = [];
  tempEventsList: FullSchedule[] = [];
  maxEvents = 0;
  resizing = false;
  resizingEvent: FullSchedule = null;
  firstDayOfNextGrid: string;
  resizeSubscription$: Subscription;

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private momentService: MomentService,
    private cdr: ChangeDetectorRef,
    private messageService: MessagesService,
    private dialogsService: DialogsService,
    private dateContentService: DateContentService,
  ) {}

  ngOnInit(): void {
    this.generateCalendar();
    this.resizeSubscription$ = fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() => this.cdr.detectChanges());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventsList || changes.vehicleList || changes.currentDate) {
      this.setMaxDateForOngoing();
      this.orderEvents();
    }
  }

  ngOnDestroy(): void {
    this.eventsList = [];
    this.resizeSubscription$.unsubscribe();
    this.store.dispatch(clearCurrentDay());
  }

  public trackByFn = (index) => index;

  public dayClicked(day: CalendarDay, popoverTrigger, popoverAttachment) {
    this.store.dispatch(setClickedDay({ date: day }));
    this.dayClickFunction(day, popoverTrigger, popoverAttachment);
  }

  // *** CALENDAR GRID GENERATION ***

  private generateCalendar(): void {
    this.month = this.fillDates();
    this.monthRow = this.dateContentService.generateMonthRow(this.month);
  }

  private fillDates(): CalendarDay[] {
    this.firstDayOfNextGrid = this.dateContentService.getFirstDayOfNextMonth(
      this.currentDate,
    );
    return this.dateContentService.getCalendarDays(this.currentDate);
  }

  getEventWidth(
    event: FullSchedule,
    row: CalendarDay[],
    day: moment.Moment,
  ): number {
    const cellWidth = document.querySelector('.calendar-row').clientWidth / 7;
    if (this.isFirst(day, event)) {
      return this.dateContentService.calculateEventWidth(
        cellWidth,
        event.duration.lower,
        event.duration.upper,
      );
    } else if (this.firstInRow(row, day, event)) {
      return this.dateContentService.calculateEventWidth(
        cellWidth,
        row[0].date.toISOString(),
        event.duration.upper,
      );
    }
  }

  setMaxDateForOngoing(): void {
    if (this.eventsList && this.eventsList.length > 0) {
      this.eventsList = this.eventsList.map((event) => {
        if (!event.duration.upper) {
          return {
            ...event,
            duration: {
              ...event.duration,
              upper: this.firstDayOfNextGrid,
              continuous: true,
            },
          };
        }
        return event;
      });
    } else {
      this.eventsList = [];
    }
  }

  orderEvents(): void {
    this.maxEvents = 0;
    this.tempEventsList = this.dateContentService.getOrderedEvents(
      this.eventsList,
    );

    for (const day of this.month) {
      const eventsForDay = this.getEventsForDay(day.date);
      this.dateContentService.assignOrdersToEvents(eventsForDay);
      this.updateMaxEvents(eventsForDay.length);
    }
  }

  private getEventsForDay(date: moment.Moment): FullSchedule[] {
    return this.tempEventsList.filter((ev) =>
      this.dateContentService.isShown(date, ev),
    );
  }

  private updateMaxEvents(count: number): void {
    if (this.maxEvents < count) {
      this.maxEvents = count;
    }
  }

  // *** TEMPLATE HELPERS ***

  getMaxRows(): string {
    return `repeat(${this.maxEvents},24px)`;
  }

  getEventMaxOrder(day: CalendarDay): number {
    const dayEvents = this.getDayEvents(day);
    return dayEvents.length > 0
      ? dayEvents.reduce((max, ev) => {
          return ev.order > max ? ev.order : max;
        }, 0) + 2
      : 1;
  }

  isFirst(date: moment.Moment, event: FullSchedule): boolean {
    return this.momentService.moment(event.duration.lower).isSame(date, 'day');
  }

  isLast(date: moment.Moment, event: FullSchedule): boolean {
    if (date !== this.momentService.moment()) return true;
    return this.momentService.moment(event.duration.upper).isSame(date, 'day');
  }

  getDayEvents(day: CalendarDay): FullSchedule[] {
    const dayEvents: FullSchedule[] = [];
    this.tempEventsList.forEach((ev) => {
      if (this.dateContentService.isShown(day.date, ev)) {
        dayEvents.push(ev);
      }
    });
    return dayEvents;
  }

  // *** EVENTS ***

  /// DATE CLICKED

  setResizingEvent(
    drag: CdkDragEnter,
    dayEvent: FullSchedule,
    direction: string,
  ): void {
    this.resizingEvent = {
      ...dayEvent,
      duration: {
        lower:
          direction === 'start'
            ? drag.container.data.date
            : dayEvent.duration.lower,
        upper:
          direction === 'end'
            ? drag.container.data.date
            : dayEvent.duration.lower,
      },
    };
  }
  removeEvent(event: FullSchedule, matMenuTrigger): void {
    matMenuTrigger.closeMenu();

    const deleteAction = () => this.deleteEvent.emit(event);

    const confirmData: ConfirmDialogData = {
      title: `Deleting Schedule?`,
      message: 'Are you sure want to delete Schedule?',
      buttonText: 'DELETE',
      action: deleteAction,
      buttonColor: 'error',
    };

    this.dialogsService.openConfirmDialog(confirmData);
  }
  onMouseClicked(event: FullSchedule, matMenuTrigger): void {
    matMenuTrigger.closeMenu();
    this.editEvent.emit({
      ...event,
      duration: event.duration.continuous
        ? { lower: event.duration.lower }
        : {
            lower: event.duration.lower,
            upper: event.duration.upper,
          },
    });
  }

  /// RESIZE EVENTS

  // positive and negative tests
  resizeDropped(
    dropEvent: CdkDragDrop<CalendarDay>,
    myEvent: FullSchedule,
    position: 'start' | 'end',
  ): boolean {
    const day = dropEvent.container.data.date;
    if (this.dateContentService.isSameDay(myEvent, day, position)) {
      this.resizingEvent = null;
      this.resizing = false;
      return false;
    }
    if (this.dateContentService.isEventOutOfBounds(myEvent, day, position)) {
      return;
    }
    const movedEvent: ScheduleInterface = {
      ...myEvent,
      vehicle: null,
      duration: this.dateContentService.getEventDuration(
        position,
        day,
        myEvent,
      ),
    } as ScheduleInterface;
    if (this.itemType === 'vehicle') {
      movedEvent.vehicle = myEvent.vehicle.id;
    }
    if (this.itemType === 'driver') {
      movedEvent.operator = myEvent.operator['operator']['id'];
    }
    this.resizingEvent = null;
    this.resizing = false;
    if (
      this.dateContentService.isEventsIntersecting(
        movedEvent,
        this.eventsList,
        this.itemType,
      )
    ) {
      this.messageService.openErrorMessage('Events are intersecting');
      return;
    }
    const deleteAction = () => this.eventMoved.emit(movedEvent);
    const confirmData: ConfirmDialogData = {
      title: `Update Schedule?`,
      message: 'Are you sure want to change Schedule?',
      buttonText: 'Update',
      action: deleteAction,
      buttonColor: 'accent',
    };
    this.dialogsService.openConfirmDialog(confirmData);
  }

  firstInRow(
    row: CalendarDay[],
    date: moment.Moment,
    event: FullSchedule,
  ): boolean {
    return (
      row.findIndex(
        (day: CalendarDay) =>
          day.date.date() === date.date() &&
          this.dateContentService.isShown(date, event) &&
          this.dateContentService.isShown(day.date, event),
      ) === 0
    );
  }

  isEventResizingAbove(current: moment.Moment): boolean {
    if (!this.resizingEvent) return false;
    return this.dateContentService.isShown(current, this.resizingEvent);
  }

  mouseEnterEvent(event: FullSchedule): void {
    this.hoveredEvent = event;
  }

  mouseLeaveEvent(): void {
    this.hoveredEvent = null;
  }

  isHovered(event: FullSchedule): boolean {
    return this.hoveredEvent && this.hoveredEvent.id === event.id;
  }
}
